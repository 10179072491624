import "./styles.scss"

import React, { useState } from "react"
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api"
import mapStyle from "utils/mapStyle"
import { withPrefix } from "gatsby"

import Button from "components/Button"

import { useLocale } from "context/locale"

const categories = [
  "dolnośląskie",
  "kujawsko-pomorskie",
  "lubelskie",
  "lubuskie",
  "łódzkie",
  "małopolskie",
  "mazowieckie",
  "opolskie",
  "podkarpackie",
  "podlaskie",
  "pomorskie",
  "śląskie",
  "świętokrzyskie",
  "warmińsko-mazurskie",
  "wielkopolskie",
  "zachodnio-pomorskie",
]

const Content = ({ pageData }) => {
  const { t } = useLocale()
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBs_dYn2cnkyG70J6RI6x3mi7-Z_nSbA7g",
  })

  const center_default = { lat: 52.05, lng: 19.37 }
  const zoom_default = 6

  const [cat, setCat] = useState(null)
  const [center, setCenter] = useState(center_default)
  const [zoom, setZoom] = useState(zoom_default)
  const [selected, setSelected] = useState(null)
  const [selected_pointer, setSelectedPointer] = useState(null)

  return (
    <section className="distributors-content">
      <div className="container-fluid">
        <div className="distributors-content__nav">
          <Button
            className={`button--round${cat === null ? " current" : ""}`}
            onClick={() => {
              setCat(null)
              setZoom(zoom_default)
              setCenter(center_default)
            }}
          >
            {t("wszystkie")}
          </Button>
          {categories?.map((item, index) => (
            <Button
              key={index}
              className={`button--round${
                item.toLowerCase() === cat ? " current" : ""
              }`}
              onClick={() => {
                setCat(item.toLowerCase())
                setZoom(zoom_default)
                setCenter(center_default)
              }}
            >
              {t(item)}
            </Button>
          ))}
        </div>

        <div className="row">
          <div className="col-lg-3">
            <div className="distributors-content__listing">
              {pageData?.acfDistributors?.distributorsContent
                ?.filter(item => (cat === null ? item : item.category === cat))
                ?.filter(item =>
                  selected_pointer === null
                    ? item
                    : item?.map?.latitude === selected_pointer
                )

                ?.map((item, index) => {
                  const position = {
                    lat: item?.map?.latitude,
                    lng: item?.map?.longitude,
                  }

                  return (
                    <button
                      key={index}
                      className={`distributors-content__item${
                        item?.map?.latitude === selected ? " current" : ""
                      }`}
                      onClick={() => {
                        setCenter(position)
                        setZoom(13)
                        setSelected(item?.map?.latitude)
                      }}
                    >
                      <small>{t(item?.category)}</small>
                      {item?.city && <h5>{item?.city}</h5>}
                      {item?.title && <h4>{item?.title}</h4>}
                      {item?.street && (
                        <address
                          dangerouslySetInnerHTML={{ __html: item?.street }}
                        ></address>
                      )}
                      {item?.email && (
                        <a href={`mailto:${item?.email}`}>{item?.email}</a>
                      )}
                      {item?.phone && (
                        <a href={`tel:${item?.phone}`}>{item?.phone}</a>
                      )}
                    </button>
                  )
                })}

              {pageData?.acfDistributors?.distributorsContent?.filter(item =>
                cat === null ? item : item.category === cat
              ).length < 1 && t("Brak wyników.")}

              <div className="distributors-content__clean">
                {selected_pointer && (
                  <button
                    onClick={() => {
                      setSelected(null)
                      setSelectedPointer(null)
                      setZoom(zoom_default)
                      setCenter(center_default)
                    }}
                  >
                    {t("Wyczyść")}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="distributors-content__map">
              {isLoaded && (
                <GoogleMap
                  center={center}
                  zoom={zoom}
                  options={{ styles: mapStyle }}
                >
                  {pageData?.acfDistributors?.distributorsContent.map(
                    (item, index) => {
                      const position = {
                        lat: item?.map?.latitude,
                        lng: item?.map?.longitude,
                      }

                      return (
                        <Marker
                          key={index}
                          icon={
                            item?.map?.latitude === selected
                              ? withPrefix("/pointer-distributor-current.svg")
                              : withPrefix("/pointer-distributor.svg")
                          }
                          position={position}
                          onClick={() => {
                            setCenter(position)
                            setSelected(item?.map?.latitude)
                            setSelectedPointer(item?.map?.latitude)
                          }}
                        />
                      )
                    }
                  )}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
