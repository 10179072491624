import "./styles.scss"

import React, { forwardRef } from "react"
import { Link } from "gatsby"
import { NumericFormat } from "react-number-format"

import { useLocale } from "context/locale"

const ProductTile = forwardRef((props, ref) => {
  const { t, lang } = useLocale()
  const { item } = props

  return (
    <Link
      ref={ref}
      to={
        lang === "en"
          ? item.uri.replace("/produkt/kleje-do-tapet", "/wallpaper-glues")
          : item.uri.replace("/produkt", "")
      }
      className="product-tile"
    >
      <div className="product-tile__image">
        <div
          style={{
            backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
          }}
        />
      </div>
      <div className="product-tile__info">
        <strong className="product-tile__title">{item?.name}</strong>
        <strong className="product-tile__price">
          <NumericFormat
            value={item?.price?.replace(/,/g, "")}
            displayType={"text"}
            thousandSeparator={" "}
            decimalScale={0}
            suffix={` ${t("zł")}`}
          />
        </strong>
      </div>
    </Link>
  )
})

export default ProductTile
